<template>
  <div class="my-10">
    <TransitionFade>
      <div class="flex flex-col justify-center items-center h-full">
        <div class="absolute top-20 right-10">
          <SelectLangs />
        </div>
        <button @click="openVideoModal" type="button" class="absolute top-10 right-10 z-50 inline-flex items-center px-3 py-2 text-sm leading-4 font-medium bg-white text-gray-600 hover:text-gray-700 rounded-lg shadow-md hover:bg-gray-50 border border-gray-200 transition hover:border-gray-300 focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring"><VideoCameraIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" /> {{ $t("acumbamailView.integrationGuide") }}</button>
        <div v-if="showVideo" class="w-full h-full bg-white bg-opacity-50 flex items-center justify-center absolute z-100">
          <div class="w-full lg:w-3/5" v-click-away="closeVideoModal">
            <span class="absolute top-32 right-32 2xl:top-24 2xl:right-80 cursor-pointer text-white" aria-hidden="true" @click="closeVideoModal">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12" style="filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.4))" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
            <VideoGuide video="https://player.vimeo.com/video/778128908?h=f4bafe70f1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
          </div>
        </div>
        <div class="px-5 md:px-10 xs:p-0 mx-auto w-full">
          <div class="w-full">
            <div class="text-center">
              <div class="inline-flex space-x-6">
                <img src="https://acumbamail.com/static/public-theme/img/logos/acumbamail-black-logo.9f7ef42753fc.svg" class="w-60" />
                <div class="flex items-center" v-if="showPoweredBySetUp">
                  <div class="inline-flex items-center space-x-2 px-4 py-2 rounded-lg bg-white shadow-md poweredby">
                    <div class="text-sm font-semibold">{{ $t("acumbamailView.powered") }}</div>
                    <Brand size="small" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full mx-auto sm:rounded-lg shadow-xl md:max-w-lg bg-white mt-3">
              <div class="w-full pt-8 py-4 px-6 md:px-8">
                <h3 class="text-gray-700 font-semibold leading-tight">{{ $t("acumbamailView.createTrial") }}</h3>
                <p class="text-gray-500 text-sm py-2">{{ $t("acumbamailView.requestTrial") }}</p>
                <div class="grid grid-cols-1 gap-4 pt-4">
                  <div class="">
                    <label for="company" class="block text-sm font-medium text-gray-700">{{ $t("acumbamailView.company") }}</label>
                    <div class="mt-1">
                      <input v-model="data.company" type="text" name="company" id="company" autocomplete="off" class="form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" />
                    </div>
                  </div>
                  <div class="">
                    <label for="language" class="block text-sm font-medium text-gray-700">{{ $t("acumbamailView.country") }}</label>
                    <div class="mt-1">
                      <select v-model="data.country" name="country" id="country" class="form-select shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md">
                        <option value="Argentina">Argentina</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brasil">Brasil</option>
                        <option value="Chile">Chile</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Honduras">Honduras</option>
                        <option value="México">México</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Panamá">Panamá</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Perú">Perú</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="República Dominicana">República Dominicana</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Otro">Otro</option>
                      </select>
                    </div>
                  </div>
                  <div class="">
                    <label for="language" class="block text-sm font-medium text-gray-700">{{ $t("mailupView.language") }}</label>
                    <div class="mt-1">
                      <select v-model="data.language" name="language" id="language" class="form-select shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md">
                        <option value="ES">Español</option>
                        <option value="EN">Ingles</option>
                        <option value="PT">Portugués</option>
                      </select>
                    </div>
                  </div>
                  <div class="">
                    <label for="number_contacts" class="block text-sm font-medium text-gray-700">{{ $t("acumbamailView.contactsQuantity") }}</label>
                    <div class="mt-1">
                      <select v-model="data.number_contacts" name="number_contacts" id="number_contacts" class="form-select shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md">
                        <option selected disabled value="">{{ $t("acumbamailView.contactsPlaceholder") }}</option>
                        <option value="0|1000">0 - 1000 {{ $t("acumbamailView.contacts") }}</option>
                        <option value="1001|5000">1001 - 5000 {{ $t("acumbamailView.contacts") }}</option>
                        <option value="5001|25000">5001 - 25&nbsp;000 {{ $t("acumbamailView.contacts") }}</option>
                        <option value="25001|50000">25&nbsp;001 - 50&nbsp;000 {{ $t("acumbamailView.contacts") }}</option>
                        <option value="50001|100000">50&nbsp;001 - 100&nbsp;000 {{ $t("acumbamailView.contacts") }}</option>
                        <option value="100001|">más de 100&nbsp;000 {{ $t("acumbamailView.contacts") }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="">
                    <label for="name" class="block text-sm font-medium text-gray-700">{{ $t("acumbamailView.ContactName") }}</label>
                    <div class="mt-1">
                      <input v-model="data.name" type="text" name="name" id="name" autocomplete="off" class="form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" />
                    </div>
                  </div>
                  <div class="">
                    <label for="email" class="block text-sm font-medium text-gray-700">{{ $t("acumbamailView.ContactEmail") }}</label>
                    <div class="mt-1">
                      <input v-model="data.email" type="email" name="email" id="email" autocomplete="off" class="form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" />
                    </div>
                  </div>
                  <div class="">
                    <label for="phone" class="block text-sm font-medium text-gray-700">{{ $t("acumbamailView.ContactPhone") }}</label>
                    <div class="mt-1">
                      <input v-model="data.phone" type="text" name="phone" id="phone" autocomplete="off" class="form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" />
                    </div>
                  </div>

                  <input type="hidden" name="language" value="ES" />
                  <input type="hidden" name="newsletter" value="1" />
                </div>
              </div>
              <div class="w-full pb-8 px-6 md:px-8">
                <div class="grid grid-cols-2 gap-4">
                  <button type="button" :class="[processing ? 'opacity-50 cursor-not-allowed' : '', 'flex items-center w-full justify-center text-sm mt-4 text-gray-600 hover:text-gray-700 rounded-lg shadow-md hover:bg-gray-50 border border-gray-200 transition relative hover:border-gray-300 focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring']" @click="$router.push({ name: 'LoginAcumbamail' })" :disabled="processing">
                    <span class="px-0 py-3 flex-1 text-center font-semibold">{{ $t("acumbamailView.platformAlreadyCreated") }}</span>
                  </button>
                  <button type="button" :class="[processing ? 'opacity-50 cursor-not-allowed' : '', 'flex items-center justify-center text-sm mt-4 text-white colorAcumbamail rounded-lg shadow-md transition relative focus:outline-none focus:ring disabled:opacity-50']" @click="validate()" :disabled="processing">
                    <span class="px-0 py-3 flex-1 text-center font-semibold">{{ $t("acumbamailView.acumbamailCreatePlatform") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionFade>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import SelectLangs from "@/components/SelectLangs"
import Brand from "@/components/Brand"
import VideoGuide from "@/components/UI/VideoGuide"
import { VideoCameraIcon } from "@heroicons/vue/solid"
import axiosClient from "@/config/AxiosClient"

export default {
  name: "AcumbamailCreateAccount",
  components: {
    SelectLangs,
    Brand,
    TransitionFade,
    VideoGuide,
    VideoCameraIcon
  },
  data: function () {
    return {
      showPoweredBySetUp: false,
      show: false,
      showVideo: false,
      data: {
        company: null,
        email: null,
        phone: null,
        country: "Argentina",
        language: "EN",
        name: null,
        number_contacts: "0|1000"
      },
      driver: localStorage.getItem("driver"),
      account_uuid: localStorage.getItem("account_uuid"),
      processing: false
    }
  },
  methods: {
    async createAccount() {
      this.processing = true
      try {
        let url = "/api/2.0/trial/account/19"
        if (this.driver && this.account_uuid) url += `/${this.driver}/${this.account_uuid}`
        await axiosClient.post(url, this.data)
        setTimeout(() => {
          this.$router.push({ name: "DashboardAcumbamail" })
        }, 1000)
      } catch (error) {
        this.$toast.error("El email ingresado ya tiene una cuenta")
      }
      this.processing = false
    },
    validate() {
      let err = false
      Object.entries(this.data)
        .filter((f) => f[1] == null)
        .forEach((keyValue) => {
          this.$toast.error(`${this.$t("mailup.createAccount.validate." + keyValue[0])}`)
          err = true
        })
      if (!err) this.createAccount()
    },
    openVideoModal: function () {
      this.showVideo = true
    },
    closeVideoModal: function () {
      this.showVideo = false
    }
  },
  async created() {
    let { data } = await axiosClient.get(`/api/2.0/account/${this.account_uuid}/form`)
    this.data.company = data.company
    this.data.name = data.name
    this.data.country = data.country ?? 'Argentina'
    this.data.language = data.language ?? 'ES'
    this.data.email = data.email
    this.data.phone = data.phone
  }
}
</script>
